<template>
  <v-app>

    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!-- <default-settings /> -->
  </v-app>

</template>
<script>
  import DefaultBar from "./AppBar.vue"
  import DefaultDrawer from "./Drawer.vue"
  import DefaultView from "./View.vue"
  import DefaultFooter from "./Footer.vue"
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultBar,
      DefaultDrawer,
      DefaultView,
      DefaultFooter,
    },
  }
</script>
