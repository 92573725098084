<template>
  <v-navigation-drawer id="default-drawer" :model-value="mini" :rail="rail" :dark="dark" :image="image"
    :disable-resize-watcher="false" :expand-on-hover="false" mobile-breakpoint="md" class="v-bar--underline"
    style="border: 0px !important">

    <div :style="'background:' +
      gradient +
      '; height: 100%; color:#fff; overflow-y: scroll;'
      ">
      <v-list-item
            lines="two"
            :prepend-avatar="'data:image/jpeg;base64,' + user.fotos"
            :subtitle="user.usr_pa +' '+ user.usr_sa"
            :title="user.usr_nom"
          ></v-list-item>

      <v-divider></v-divider>
      <!-- <default-drawer-header />

      <v-divider class="mx-3 mb-2" /> -->
      <default-list :items="itemsMenu" />
    </div>

    <template #append>
      <div
        class="pa-4 text-center"
        :style="'background:' + gradient + '; height: 100%; color:#4a657a;'"
      >
        <v-btn
          v-if="!rail"
          class="text-none mb-4"
          color="#4a657a"
          to="/tickets"
          small
          text
        >
          Tickets
        </v-btn>
        <v-btn
          v-else
          class="text-none mb-4"
          variant="text"
          color="#4a657a"
          to="/tickets"
          icon="mdi-ticket-confirmation"
          size="x-large"
        >
          <v-icon color="#4a657a"> mdi-ticket-confirmation </v-icon>
          <v-tooltip activator="parent" location="bottom">Tickets</v-tooltip>
        </v-btn>

        <!-- <v-btn
          block
          class="text-none"
          color="secondary"
          href="https://store.vuetifyjs.com/products/vuetify-material-dashboard-pro"
        >
          <v-icon left>
            mdi-package-up
          </v-icon>

          Upgrade to Pro
        </v-btn> -->
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
// import { get, sync } from "vuex-pathify";
import { mapState, mapGetters } from "vuex";
import { useStorage } from "vue3-storage";
// import DefaultDrawerHeader from "./widgets/DrawerHeader";
import DefaultList from "./List";
// import { withDirectives } from "vue";
const storage = useStorage();
export default {
  name: "DefaultDrawer",
  components: {
    // DefaultDrawerHeader,
    DefaultList
  },
  data: () => ({
    selectedItem: [],
    user:{},
    profile: [
      { title: "Profile" },
      { title: "Settings" },
      { divider: true },
      { title: "Log out", method: () => this.logout() }
    ]
  }),
  computed: {
    ...mapState("user", [
      "user",
    ]),
    ...mapGetters("user", ["dark", "gradient", "image"]),
    ...mapState("app", [
      "itemsMenu",
      "version",
      "drawer",
      "drawerImage",
      "mini",
      "rail",
      "reloadPage"
    ])
  },
  mounted() {
    // console.log("gradient", this.gradient)
    // if (this.reloadPage) {
    //   // Refresh the page
    //   this.$store.commit("app/reloadPage", false);
    //   this.$store.dispatch("user");
    // }
    // console.log("getUser", storage.getItem("user_storage"));

    const d = new Date(storage.getItem("user_storage").data.expire);
    const d2 = new Date();

    if (d2 > d) {
      this.logout();
    } else {
      let data = JSON.parse(storage.getItem("user_storage").data.value);
      this.user = data;
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch("user/logout").then(() => {
        window.location.href = process.env.BASE_URL;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer__img img {
  width: inherit !important;
}

#default-drawer {
  .v-list-item {
    margin-bottom: 8px;
  }

  .v-list-item::before,
  .v-list-item::after {
    display: none;
  }

  .v-list-group__header__prepend-icon,
  .v-list-item__icon {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 4px;
  }

  &.v-navigation-drawer--mini-variant .v-list-item {
    justify-content: flex-start !important;
  }
}

.v-navigation-drawer__img img {
  object-fit: fill !important;
}

/*Una vez alcance los 1490px realizas el cambio*/
@media screen and (min-width: 1024px) {
  /* .v-navigation-drawer{
    transform: translateX(0%) !important;
  } */
}
</style>
