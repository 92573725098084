import { createStore } from "vuex";
// Modules migrate
import * as modules from './modules'
import pathify from '@/plugins/vuex-pathify'

const store = new createStore({
  modules,
  plugins: [
    pathify.plugin,
  ],
});

store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return
  store.dispatch('user/update', mutation)
})
store.dispatch('app/init')
store.dispatch('ini/doLogin')
store.dispatch('user/setUsers')

export default store
