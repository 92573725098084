/**
 * plugins/app.js
 *
 * Automatically loads and bootstraps files
 * in the `./src/components/` folder.
 */

// Imports
export default {
  install: (app, options) => {
    // Plugin code goes here
    const requireComponent = require.context(
      "@/components/custom",
      true,
      /\.vue$/
    );

    for (const file of requireComponent.keys()) {
      const componentConfig = requireComponent(file);

      app.component(
        componentConfig.default.name,
        componentConfig.default || componentConfig ,{inheritAttrs: false}
      );
    }
  }
};
