<template>
  <v-btn class="ml-0 mr-4" elevation="1" fab small @click="toggleSidebar">
    <v-icon>
      {{ rail ? "mdi-format-list-bulleted" : "mdi-dots-vertical" }}
    </v-icon>
  </v-btn>
</template>

<script>
// Utilities
// import { sync } from 'vuex-pathify'
import { mapMutations, mapState } from "vuex";

export default {
  name: 'DefaultDrawerToggle',
  methods: {
  ...mapMutations('app',["navbarMinimize"]),

  toggleSidebar() {
    this.navbarMinimize();
  },
},
computed: {
  ...mapState('app', [
    'mini',
    'rail'
  ]),
}
}
</script>
