<template>
  <v-locale-provider locale="es">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-locale-provider>
</template>
<script>
// Styles
import "@/styles/overrides.sass";

import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  metaInfo: {
      title: 'CGA',
      titleTemplate: '%s | CGA',
      htmlAttrs: { lang: 'es' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  // components: {
  //   // Sidenav,
  //   DefaultDrawer, //original Sidenave
  //   Configurator,
  //   Navbar,
  //   AppFooter,
  //   DefaultView
  // },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  // async mounted() {

  // },
  async mounted() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }

    // ----------------------------------------
    let mdata = this.$route;
    console.log("mounted", mdata);

    if (mdata) {
      // setTimeout(async () => {
        this.$store.dispatch('app/getItemsMenu', { mdata })
          .then(respuesta => {
            console.log("respuesta itemsMenu", respuesta);
          })
          // .then((res) => this.serStatus= res.status)
          .catch((error) => console.log("errores app", error));
      // }, "4000");
    }
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #393c42;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #41669e;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}
</style>
