<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="550px"
    >
      <material-alert
        color="warning"
        icon="mdi-bell"
      >
        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            {{ stringt }}
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <!-- <v-spacer /> -->
            <v-btn
              class="mt-2 col-3"
              color="success"
              small
              dark
              @click="aceptDialog"
            >
              Aceptar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn
              class="mt-2 col-3"
              color="error"
              small
              dark
              @click="salirDialog"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </material-alert>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'DialogConfirm',
    props: {
      showDialog: {
        type: Boolean,
        required: false,
        default: false,
      },
      textDialog: {
        type: String,
        required: false,
        default: '',
      },
    },
    data () {
      return {
        dialog: false,
        valid: true,
        stringt: '',
      }
    },
    watch: {
      showDialog: {
        immediate: true,
        deep: true,
        handler (newValue) {
          this.dialog = newValue
        },
      },
      textDialog: {
        immediate: true,
        deep: true,
        handler (newValue) {
          this.stringt = newValue
        },
      },
    },
    methods: {
      aceptDialog () {
        this.$emit('acept-form')
      },
      salirDialog () {
        this.$emit('close-form', false)
      },
    },
  }
</script>
<style lang="scss" scoped>

</style>
