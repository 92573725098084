<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn class="ml-2" min-width="0" color="#fff" text v-bind="props">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list nav>
      <!-- <v-list-item-group
        v-model="selectedItem"
        color="primary"
      > -->
      <v-list-item >
        <v-list-item-title class="text-center">
          <v-icon size="x-large" color="blue-darken-2" icon="mdi-badge-account-outline"></v-icon>
          <p>{{user.usr_nom}} {{user.usr_pa}} {{user.usr_sa}}</p>
          <p>{{user.usr_login}}</p>
          <p>{{user.emp_num}}</p>
          </v-list-item-title
        >
      </v-list-item>
      <v-list-item >
        <v-list-item-title>
          <v-btn block variant="outlined" @click="logout" color="red">
            <v-icon size="large" color="red-darken-2" icon="mdi-power"></v-icon>
          Salir
          </v-btn>
          </v-list-item-title
        >
      </v-list-item>

      <!-- <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      <!-- </v-list-item-group> -->

      <!-- <v-list-item v-for="item in items" :key="item.title" @click="item.method"> -->

      <!-- <v-list-item @click="logout"> -->
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { useStorage } from "vue3-storage";
const storage = useStorage();

export default {
  name: "DefaultAccount",

  data: () => ({
    selectedItem: [],
    user:{},
    profile: [
      { title: "Profile" },
      { title: "Settings" },
      { divider: true },
      { title: "Log out", method: () => this.logout() }
    ]
  }),
  mounted () {

  //  console.log("getUser",storage.getItem("user_storage"))

    const d = new Date(storage.getItem("user_storage").data.expire);
    const d2 = new Date();

    if(d2 > d){
      this.logout()
    } else {
      let data = JSON.parse(storage.getItem("user_storage").data.value)
      this.user = data
      this.$globalData.idUsr = data.emp_num
      this.$globalData.usr = data.usr_nom +' '+ data.usr_pa +' '+ data.usr_sa
    }

  },
  computed: {
    ...mapState("user", [
      "user",
    ])
  },
  methods: {
    logout: function () {
      this.$store.dispatch("user/logout").then(() => {
        window.location.href = process.env.BASE_URL;
      });
    }
  }
};
</script>
