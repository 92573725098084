<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    @click="complexClickHandler(item)"
    active-class="primary grey--text"
    link
    class="py-1"
    v-bind="$attrs"
   
    :title="item.title"
  >
  </v-list-item>

  <!-- :prepend-icon="item.icon" -->
</template>

<script>
export default {
  name: "DefaultListItemXajax",

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    }
  },
  methods: {
    complexClickHandler(item) {
      // document.getElementById('iframe1').contentWindow.xajax_muestra_sistema('61','fgralesPermisos');$('#menuToggle').click();
      this.$globalData.value.menuLabel = item.xajax;
      // this.$globalData.value.title = item.title;
      // console.log("velueItem: ", this.$globalData.menuLabel);
      // Vue.set(Vue.prototype, '$appName', title)

      console.log('ListXajax: ',this.$globalData.value.menuLabel)
      this.$router.push({ path: '/content/'+item.title});
    }
  }
};
</script>
<style>
.containeraf {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 1;
  bottom: 0;
  right: 1;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
