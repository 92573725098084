<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    link
    :prepend-icon="item.icon"
    :to="item.to"
    active-class="primary white--text"
  >
  <v-list-item-content v-if="item.title">
      <div v-html="item.title" style="font-size: small;"></div>
    </v-list-item-content>

  <!-- <v-list-item-title v-text="item.text"></v-list-item-title> -->
    <!-- <v-list-item-content v-if="item.title">
      <div v-html="item.title" style="font-size: small;"></div>
    </v-list-item-content> -->
    <!-- <v-divider class="border-opacity-70"></v-divider> -->
  </v-list-item>
</template>

<script>
  export default {
    name: 'DefaultListItem',

    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      title () {
        const matches = this.item.title.match(/\b(\w)/g)

        return matches.join('')
      },
    },
  }
</script>
