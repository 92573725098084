<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="dialog" width="auto">
            <v-card>
                <img :src="url" alt="">
                <v-card-actions>
                    <v-spacer></v-spacer>
        
                    <v-btn
                      text="Cerrar"
                      variant="text"
                      @click="$emit('close')"
                    ></v-btn>
    
                  </v-card-actions>
            </v-card>
            
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ImagenDialog',
    props: {
        openD: {
            type: Boolean,
            default: false
        },
        imagen: {
            type: String,
            default: ''
        },
    },
    watch: {
        openD: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.dialog = newValue
            }
        },
        imagen: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.url = newValue
            }
        },
    },
        data() {
            return {
                dialog: false,
                url:''
            }
        },
    }
</script>

<style lang="scss" scoped></style>