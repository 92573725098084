<template>
  <v-container style="width: 170px;">
      <v-img
        :width="170"
        aspect-ratio="16/9"
        cover
        src="@/assets/logoseguridad.png"
      ></v-img>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: {
    version: get("app/version"),
    ...mapState("app", [
      "mini",
      "rail",
    ])
  }
};
</script>
<style>
/*Una vez alcance los 1490px realizas el cambio*/
@media screen and (max-width: 800px) {
  .min-ssp{
    display: none !important;
  }

}
</style>
