<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    width="400"
  >
    <v-card
      color="blue darken-3"
      dark
    >
      <v-card-text>
        <br>
        <p class="text-center text-h5">
          {{ text }}
        </p>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
          dark
        />
        <br>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LoaderComponent',
    props: {
      loader: {
        type: Boolean,
        required: false,
        default: false,
      },
      text: {
        type: String,
        default: 'Cargando, espera un momento...',
      },
    },
    data () {
      return {
        dialog: false,
      }
    },
    watch: {
      loader: {
        immediate: true,
        deep: true,
        handler (newValue, oldValue) {
          this.dialog = newValue
        },
      },
    },

  }
</script>

<style lang="scss" scoped>

</style>
