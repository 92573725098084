<template>
  <v-sheet class="mx-auto mt-10 border-radius-xl card-shadow">
    <v-row class="ma-1">
      <v-col sm="4" md="4" lg="4">
        <v-avatar
          :color="dataStat.color"
          class="shadow-dark bg-gradient-success border-radius-xl mt-n8"
          style="height: 100%; min-width: 100%; width: 100%"
        >
          <v-icon
            :size="(dataStat.large = !dataStat.iconSmall ? 'large' : 'small')"
            :icon="dataStat.icon"
            class="ma-10"
          />
        </v-avatar>
      </v-col>

      <v-col sm="8" md="8" lg="8" class="text-end">
        <p>{{ dataStat.title }}</p>
        <h3>
          {{ dataStat.value }}
        </h3>
      </v-col>
    </v-row>
    <v-divider class="mx-3 mb-2" />
    <p class="ma-2 pb-2 text-body">
      <span><v-icon x-small :icon="dataStat.actionIcon" /></span>
      <span class="font-weight-light ms-1">
        {{ dataStat.actionText }}
      </span>
    </p>
  </v-sheet>
</template>

<script>
export default {
  name: "Stats",
  components: {},
  props: {
    stat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataStat: null,
    };
  },
  watch: {
    stat: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // console.log(oldValue);
        this.dataStat = newValue;
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.border-radius-xl {
  border-radius: 0.75rem !important;
  border-width: thin;
}

.shadow-dark {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}

.v-application .mt-n8 {
  margin-top: -32px !important;
}

.card-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.v-application .text-body {
  color: #7b809a !important;
}

.v-application .font-weight-light {
  font-weight: 300 !important;
}

.v-application--is-ltr .ms-1 {
  margin-left: 4px !important;
}
</style>
