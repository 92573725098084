<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="titlePicker"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        dense
        outlined
        :required="required"
        :rules="rules"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
    >
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Cancelar
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="selectDate"
      >
        Seleccionar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: 'DatePicker',
    props: {
      titlePicker: {
        type: String,
        default: 'Seleccionar fecha',
      },
      rules: {
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: [v => !!v || 'Campo requerido'],
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        date: '',
        menu: false,
      }
    },
    methods: {
      selectDate () {
        this.$refs.menu.save(this.date)
        this.$emit('select-date', this.date)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
