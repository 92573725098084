<template>
  <!-- color="#101f32" -->
  <v-footer
    id="default-footer"
    color="#101f32"
    dark
    absolute
    app
    inset
    class="d-flex flex-column"
  >
    <links />
  </v-footer>
</template>

<script>
  // Components
  import Links from './widgets/Links.vue'

  export default {
    name: 'Footer',
    components: { Links },
  }
</script>
